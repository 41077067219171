import Config from '../config';
import Ajax from '../common/ajax';
import storage from '../common/storage';
import React from 'react';

const _permissionStorageKey = 'ff_permissions';
const _brandingStorageKey = 'ff_branding';

$.ajaxSetup({
  error: (xhr) => {
    if (xhr.status === 403) {
      storage.removeItem(_permissionStorageKey);
    }
  },
});

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function _getUserQuota() {
  let perms;
  Ajax.getSync(`${Config.apiHost}quotas/user_quota`).done((resp) => {
    console.log('Got permissions!');
    perms = JSON.stringify(resp);
  });
  return perms && JSON.parse(perms);
}

function getBranding() {
  let branding = storage.getItem(_brandingStorageKey);
  console.log('IS BRANDING OBJECT EXPIRED:', !branding);

  if (!branding) {
    Ajax.getSync(`${Config.apiHost}branding/`).done((resp) => {
      console.log('Got branding!');
      branding = JSON.stringify(resp);
      storage.setItem(_brandingStorageKey, branding, 60 * 5);
    });
  }

  return branding && JSON.parse(branding);
}

export function getUpdatePermissions() {
  delete storage.removeItem(_permissionStorageKey);
  return _getUserQuota();
}

const _context = getUpdatePermissions();
let contextValue = {
  uuid: uuidv4(),
};

if (Config.isBrandEnabled) {
  const _brandingContext = getBranding();
  contextValue = {
    ...contextValue,
    ..._context,
    ..._brandingContext,
  };
} else {
  contextValue = {
    ...contextValue,
    ..._context,
  };
}

export const AppContext = React.createContext(contextValue);

export function hasProofread() {
  return _context && _context.has_proofread;
}

export function hasSpellchecker() {
  return _context && _context.has_spellchecker;
}

export function hasEbook() {
  return _context && _context.has_ebook;
}

export function hasImageEditor() {
  return _context && _context.has_image_editor;
}

export function hasPresentation() {
  return _context && _context.has_presentation;
}

export function canImportDoc() {
  return _context && _context.can_import_doc;
}

export function isLoggedIn() {
  return !!(_context && _context.user && _context.user.email);
}

export function getUser() {
  return _context.user;
}

export function isUserFirstDay() {
  return _context.user && _context.user.days_since_join <= 1;
}

export function isSuperuser() {
  return _context && _context.user && _context.user.is_superuser;
}

export function isStaff() {
  return _context && _context.user && _context.user.is_staff;
}

export function canCancelPlan() {
  return _context && _context.can_cancel_plan;
}

export function hasActivePlan() {
  return _context && _context.has_valid_paid_plan;
}
